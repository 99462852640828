<div class="incentive-feedback">
  <img
    loading="lazy"
    class="incentive-feedback__close-btn"
    (click)="closeDialog()"
    src="{{ assetsPath + 'close' }}.svg"
  />
  <p class="heading2--extra-bold content-main-color text-center">
    {{ 'DYNAMIC_INCENTIVE.FEEDBACK_FORM.HEAR_MORE' | translate }}
  </p>
  <p class="body2--bold content-main-color">
    {{ 'DYNAMIC_INCENTIVE.FEEDBACK_FORM.SATISFACTION' | translate }}
  </p>
  <div class="incentive-feedback__card">
    <button
      *ngFor="let status of feedbackStatuses"
      (click)="onStatusSelected(status)"
      class="incentive-feedback__card__button"
      [ngClass]="{
        'incentive-feedback__card__button--selected-card-very-happy':
          status.name === selectedStatusName && status.id === verySatisfiedId,
        'incentive-feedback__card__button--selected-card-happy':
          status.name === selectedStatusName && status.id === satisfiedId,
        'incentive-feedback__card__button--selected-card-sad':
          status.name === selectedStatusName && status.id === notSatisfiedId
      }"
    >
      <img src="{{ status.image }}" />
      <p
        class="incentive-feedback__card__button--text body2--bold"
        [ngClass]="{
          'incentive-feedback__card__button--selected-card-very-happy--text':
            status.name === selectedStatusName && status.id === verySatisfiedId,
          'incentive-feedback__card__button--selected-card-happy--text':
            status.name === selectedStatusName && status.id === satisfiedId,
          'incentive-feedback__card__button--selected-card-sad--text':
            status.name === selectedStatusName && status.id === notSatisfiedId
        }"
      >
        {{ status.name }}
      </p>
    </button>
  </div>
  <p class="body2--bold content-main-color">
    {{ 'DYNAMIC_INCENTIVE.FEEDBACK_FORM.ADD_COMMENT' | translate }}
    <span class="body2--bold content-medium-color">{{
      'DYNAMIC_INCENTIVE.FEEDBACK_FORM.OPTIONAL' | translate
    }}</span>
  </p>
  <form class="incentive-feedback" [formGroup]="dynamicIncentiveForm">
    <input
      placeholder="{{ 'DYNAMIC_INCENTIVE.FEEDBACK_FORM.COMMENT_PLACEHOLDER' | translate }}"
      class="incentive-feedback__input"
      formControlName="feedbackComment"
    />
    <button
      [disabled]="!selectedStatusName"
      class="incentive-feedback__submit-btn"
      type="button"
      (click)="onSubmitFeedback()"
    >
      {{ 'DYNAMIC_INCENTIVE.FEEDBACK_FORM.SUBMIT' | translate }}
    </button>
  </form>
</div>
