import { NgClass, NgFor } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FeedbackRate } from 'src/app/core/domain/dynamic-incentive-program.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { FeedbackDynamicIncentiveProgramUseCase } from 'src/app/core/usecases/dynamic-incentive-program/feedback-dynamic-incentive-program.usecase';
import {
  DYNAMIC_INCENTIVE_NOT_SATISFIED,
  DYNAMIC_INCENTIVE_SATISFIED,
  DYNAMIC_INCENTIVE_VERY_SATISFIED,
} from 'src/app/presentation/shared/constants';

@Component({
  selector: 'app-submit-feedback-form',
  templateUrl: './submit-feedback-form.component.html',
  styleUrls: ['./submit-feedback-form.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class SubmitFeedbackFormComponent {
  public feedbackStatuses = [
    {
      name: this._translateService.instant('DYNAMIC_INCENTIVE.FEEDBACK_FORM.HAPPY'),
      id: DYNAMIC_INCENTIVE_VERY_SATISFIED,
      image:
        'https://taager-public-media.s3.eu-west-1.amazonaws.com/recommended-products-survey/happy.svg',
    },
    {
      name: this._translateService.instant('DYNAMIC_INCENTIVE.FEEDBACK_FORM.SATISFIED'),
      id: DYNAMIC_INCENTIVE_SATISFIED,
      image:
        'https://taager-public-media.s3.eu-west-1.amazonaws.com/recommended-products-survey/happy-orange.svg',
    },
    {
      name: this._translateService.instant('DYNAMIC_INCENTIVE.FEEDBACK_FORM.SAD'),
      id: DYNAMIC_INCENTIVE_NOT_SATISFIED,
      image:
        'https://taager-public-media.s3.eu-west-1.amazonaws.com/recommended-products-survey/sad.svg',
    },
  ];

  public assetsPath = 'assets/img/';

  public selectedStatusName: string;

  public selectedStatusId: FeedbackRate;

  public satisfiedId: string = DYNAMIC_INCENTIVE_SATISFIED;

  public verySatisfiedId: string = DYNAMIC_INCENTIVE_VERY_SATISFIED;

  public notSatisfiedId: string = DYNAMIC_INCENTIVE_NOT_SATISFIED;

  public dynamicIncentiveForm = new FormGroup({
    feedbackComment: new FormControl(''),
  });

  constructor(
    private _translateService: TranslateService,
    private _dialog: MatDialog,
    private _feedbackDynamicIncentiveProgramUseCase: FeedbackDynamicIncentiveProgramUseCase,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _toast: ToastrService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {}

  closeDialog(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'dynamic_incentive_feedback_popup_closed',
    });
    this._dialog.closeAll();
  }

  onStatusSelected(status: any): void {
    this.selectedStatusName = status.name;
    this.selectedStatusId = status.id;
  }

  onSubmitFeedback(): void {
    const data = {
      id: this.data,
      feedback: this.dynamicIncentiveForm.get('feedbackComment')!.value!,
      rate: this.selectedStatusId,
    };
    this._feedbackDynamicIncentiveProgramUseCase.execute(data).subscribe({
      next: () => {
        this._logMixpanelEventUseCase.execute({
          eventName: 'dynamic_incentive_feedback_submitted',
        });
        this._toast.success(
          this._translateService.instant('DYNAMIC_INCENTIVE.FEEDBACK_FORM.SUCCESS'),
        );
        this._dialog.closeAll();
      },
      error: (err) => {
        this._toast.error(err.error.description);
      },
    });
  }
}
