import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { mixPanelEvent } from '@features/activities/data';
import { getCurrentLocaleCode } from '@mongez/localization';
import { TranslateModule } from '@ngx-translate/core';
import { parseError } from '@presentation/shared/error';
import { toastError } from '@presentation/shared/toast';
import {
  DynamicIncentiveProgramModel,
  LevelsData,
  OrderProgressData,
} from '../../../../../core/domain/dynamic-incentive-program.model';
import { LocalizedData } from '../../../../../core/domain/localization.model';
import { AcceptDynamicIncentiveProgramUseCase } from '../../../../../core/usecases/dynamic-incentive-program/accept-dynamic-incentive-program.usecase';
import { DeclineDynamicIncentiveProgramUseCase } from '../../../../../core/usecases/dynamic-incentive-program/decline-dynamic-incentive-program.usecase';
import { appUrlsConstantsInjectionToken } from '../../../../../data/injection-tokens/app-urls-constants.injection-token';
import { LocalizedComponent } from '../../../../base/localized.component';
import { CountdownTimerPipe } from '../../../../shared/pipes/countdown-timer.pipe';
import { CurrencyShortNamePipe } from '../../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../../shared/pipes/currency-translate.pipe';
import { MultitenancyService } from '../../../../shared/services/multitenancy.service';
import { calculateDateDifference } from '../../../../shared/utilities/caluclate-date-difference.utility';
import { DateWrapperUtility } from '../../../../shared/utilities/date-wrapper.utility';
import { SubmitFeedbackFormComponent } from '../../dynamic-incentive-program/submit-feedback-form/submit-feedback-form.component';

@Component({
  selector: 'app-incentive-program-challenge',
  standalone: true,
  templateUrl: './incentive-program-challenge.component.html',
  styleUrls: ['./incentive-program-challenge.component.scss'],
  imports: [
    NgClass,
    NgIf,
    MatProgressBarModule,
    CommonModule,
    NgFor,
    TranslateModule,
    CountdownTimerPipe,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
  ],
})
export class IncentiveProgramChallengeComponent
  extends LocalizedComponent
  implements OnInit, OnChanges
{
  @Input() incentiveProgramData: DynamicIncentiveProgramModel;

  @Input() shouldOnlyDisplayIncentiveWidget = false;

  @Output() actionClicked: EventEmitter<void> = new EventEmitter();

  public assetsPath = 'assets/img/dynamic-incentive/';

  public dynamicIncentiveData: DynamicIncentiveProgramModel;

  public dynamicLevels: LevelsData;

  public orderProgressData: OrderProgressData;

  public programStatus: string;

  public termsAndConditions: LocalizedData[];

  public incentiveProgramName: string;

  public loadingProgress: number;

  public acceptedDate: string;

  public durationInDays: number;

  public endDateCountDownTimer: number;

  public extraTimeCountDownTimer: number;

  public expiryDateCountDownTimer: number;

  public extraTimeInDays: number;

  public isButtonsDisabled = false;

  constructor(
    private _acceptDynamicIncentiveProgramUseCase: AcceptDynamicIncentiveProgramUseCase,
    private _declineDynamicIncentiveProgramUseCase: DeclineDynamicIncentiveProgramUseCase,
    private _multitenancyService: MultitenancyService,
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDynamicIncentiveProgram();
  }

  public get currency(): string {
    return this.localizedValue(this._multitenancyService.getCurrentCountry().currency);
  }

  // Watch for changes in the incentive program data
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incentiveProgramData) {
      this.getDynamicIncentiveProgram();
    }
  }

  public get isAchieved(): boolean {
    return this.dynamicIncentiveData.status === 'ACHIEVED';
  }

  public get challengeTimeIsUp(): boolean {
    return this.dynamicIncentiveData.endDate < new Date();
  }

  public get shouldDisplayProgressBar(): boolean {
    // progress bar should be always displayed unless merchant has completed all orders
    return this.programStatus !== 'ACHIEVED';
  }

  public get hasCarnival(): boolean {
    return (this.dynamicLevels.rewardPerOrderExceedingLevelCap || 0) > 0;
  }

  public get rewardsNotEqualToBaseRewards(): boolean {
    return (
      this.dynamicIncentiveData.challengeResult.baseRewards !==
      this.dynamicIncentiveData.challengeResult.rewards
    );
  }

  public get challengeInProgress(): boolean {
    return this.programStatus === 'IN_PROGRESS';
  }

  public get shouldDisplayCarnivalDetails(): boolean {
    if (!this.hasCarnival) return false;

    if (this.challengeStillHasTimeToEnd) return true;

    // otherwise challenge end date is passed, now status is either IN_PROGRESS_EXTRA_TIME, ACHIEVED or NOT_ACHIEVED
    return (
      (this.dynamicIncentiveData.challengeResult?.rewards || 0) >
      (this.dynamicIncentiveData.challengeResult?.baseRewards || 0)
    );
  }

  public get challengeStillHasTimeToEnd(): boolean {
    return this.dynamicIncentiveData.endDate > new Date();
  }

  public get didNotWinInCarnival(): boolean {
    // check if there is still remaining time to the end of the challenge, if yes, then skip this check
    if (this.dynamicIncentiveData.endDate > new Date()) return false;

    const inDesiredStatus = ['NOT_ACHIEVED', 'IN_PROGRESS_EXTRA_TIME', 'ACHIEVED'].includes(
      this.programStatus,
    );

    if (
      inDesiredStatus &&
      this.dynamicIncentiveData.challengeResult?.baseRewards ===
        this.dynamicIncentiveData.challengeResult.rewards
    )
      return true;

    return false;
  }

  public get rewardIsNotSameAsBaseReward(): boolean {
    return (
      this.dynamicIncentiveData.challengeResult.baseRewards !==
      this.dynamicIncentiveData.challengeResult.rewards
    );
  }

  public hasBaseRewards(): boolean {
    return this.dynamicIncentiveData.challengeResult.baseRewards !== undefined;
  }

  public get hasGracePeriod(): boolean {
    // Grace period is the time between the end date and the extra time
    return this.extraTimeCountDownTimer > 0 && this.programStatus === 'IN_PROGRESS_EXTRA_TIME';
  }

  public get shouldDisplayFeedback(): boolean {
    return ['NOT_ACHIEVED', 'ACHIEVED', 'IN_PROGRESS_EXTRA_TIME'].includes(this.programStatus);
  }

  getDynamicIncentiveProgram(): void {
    this.dynamicIncentiveData = this.incentiveProgramData;
    [this.dynamicLevels] = this.dynamicIncentiveData.levels;
    this.orderProgressData = this.dynamicIncentiveData.ordersProgressResult;
    this.programStatus = this.dynamicIncentiveData.status;
    this.incentiveProgramName = this.dynamicIncentiveData.name.ar;
    this.termsAndConditions = this.dynamicIncentiveData.termsAndConditions;
    this.durationInDays = Math.round(this.dynamicIncentiveData.durationInHours / 24);
    this.extraTimeInDays = Math.round(
      calculateDateDifference(
        this.dynamicIncentiveData.extraTime,
        this.dynamicIncentiveData.endDate,
      ) /
        (1000 * 3600 * 24),
    );
    const acceptanceDate = this.dynamicIncentiveData.startDate.toDateString();
    this.acceptedDate = this.calculateCurrentFormattedDate(acceptanceDate);

    this.countdownDate();
    this.calculateLoadingProgress();
  }

  public achievedOrdersProgress(): string {
    if (this.isLTR) {
      // reverse the order of the numbers
      if (this.dynamicIncentiveData.orderType === 'confirmed') {
        return `${this.orderProgressData?.confirmedOrders}/${this.dynamicLevels.numOfOrders}`;
      }

      if (this.dynamicIncentiveData.orderType === 'delivered') {
        return `${this.orderProgressData?.deliveredOrders}/${this.dynamicLevels.numOfOrders}`;
      }

      if (this.dynamicIncentiveData.orderType === 'received') {
        return `${this.orderProgressData?.totalPlacedOrders}/${this.dynamicLevels.numOfOrders}`;
      }

      if (this.dynamicIncentiveData.orderType === 'prepaid') {
        return `${this.orderProgressData?.prePaidOrdersCount}/${this.dynamicLevels.numOfOrders}`;
      }
    }

    if (this.dynamicIncentiveData.orderType === 'confirmed') {
      return `${this.dynamicLevels.numOfOrders}/${this.orderProgressData?.confirmedOrders}`;
    }

    if (this.dynamicIncentiveData.orderType === 'delivered') {
      return `${this.dynamicLevels.numOfOrders}/${this.orderProgressData?.deliveredOrders}`;
    }

    if (this.dynamicIncentiveData.orderType === 'received') {
      return `${this.dynamicLevels.numOfOrders}/${this.orderProgressData?.totalPlacedOrders}`;
    }

    if (this.dynamicIncentiveData.orderType === 'prepaid') {
      return `${this.dynamicLevels.numOfOrders}/${this.orderProgressData?.prePaidOrdersCount}`;
    }

    return '';
  }

  public rewardPerExtraOrder(): string {
    const rewardPerExtraOrder = this.dynamicLevels.rewardPerOrderExceedingLevelCap || 0;

    if (!rewardPerExtraOrder) return `0 ${this.currency}`;

    // now return it with the currency
    return `${rewardPerExtraOrder} ${this.currency}`;
  }

  public numberOfAdditionalOrders(): number {
    return this.dynamicIncentiveData.challengeResult.additionalAchievedOrders || 0;
  }

  public totalRewardsForExtraOrders(): number {
    return (
      this.numberOfAdditionalOrders() * (this.dynamicLevels.rewardPerOrderExceedingLevelCap || 0)
    );
  }

  /**
   * We will use this method instead of `acceptedDate` so when merchant changes the language, the date will be updated
   */
  public getAcceptedDate(): string {
    return this.calculateCurrentFormattedDate(this.dynamicIncentiveData.startDate.toDateString());
  }

  calculateCurrentFormattedDate(date: string): string {
    const selectedLanguage = getCurrentLocaleCode();
    const { year, month, day } = DateWrapperUtility.getFormattedDateParts(
      date,
      { day: 'D', month: 'MMM', year: 'YYYY' },
      selectedLanguage,
    );
    return `${day} ${month} ${year}`;
  }

  countdownDate(): void {
    const checkDates = (): void => {
      const { expiryDate } = this.dynamicIncentiveData;
      const { endDate } = this.dynamicIncentiveData;
      const { extraTime } = this.dynamicIncentiveData;
      const currentDate = new Date();
      this.expiryDateCountDownTimer = calculateDateDifference(expiryDate, currentDate);
      this.endDateCountDownTimer = calculateDateDifference(endDate, currentDate);
      this.extraTimeCountDownTimer = calculateDateDifference(extraTime, currentDate);

      if (this.expiryDateCountDownTimer <= 0) {
        this.expiryDateCountDownTimer = 0;
        this.isButtonsDisabled = true;
      }
      this.endDateCountDownTimer = this.endDateCountDownTimer > 0 ? this.endDateCountDownTimer : 0;
      this.extraTimeCountDownTimer =
        this.extraTimeCountDownTimer > 0 ? this.extraTimeCountDownTimer : 0;
    };
    setInterval(() => {
      checkDates();
    }, 1000);
    checkDates();
  }

  onDeclineClicked(): void {
    const challengeBusinessId = this.dynamicIncentiveData.merchantChallengeBusinessId;
    this._declineDynamicIncentiveProgramUseCase.execute(challengeBusinessId).subscribe({
      next: () => {
        this.actionClicked.emit();
        mixPanelEvent('dynamic_incentive_challenge_rejected');
      },
      error: (error) => {
        toastError(parseError(error));
      },
    });
  }

  onAcceptClicked(): void {
    const challengeBusinessId = this.dynamicIncentiveData.merchantChallengeBusinessId;
    this._acceptDynamicIncentiveProgramUseCase.execute(challengeBusinessId).subscribe({
      next: () => {
        this.actionClicked.emit();
        mixPanelEvent('dynamic_incentive_challenge_accepted');
      },
      error: (error) => {
        toastError(parseError(error));
      },
    });
  }

  onFeedbackClicked(): void {
    mixPanelEvent('dynamic_incentive_feedback_clicked');
    this._dialog.open(SubmitFeedbackFormComponent, {
      width: '700px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: this.dynamicIncentiveData.merchantChallengeBusinessId,
    });
  }

  calculateLoadingProgress(): void {
    const ordersPerLevel = this.dynamicLevels.numOfOrders;
    const confirmedOrders = this.orderProgressData?.confirmedOrders;
    const deliveredOrders = this.orderProgressData?.deliveredOrders;
    const receivedOrders = this.orderProgressData?.totalPlacedOrders;
    const prepaidOrders = this.orderProgressData?.prePaidOrdersCount;
    if (this.dynamicIncentiveData.orderType === 'confirmed') {
      this.loadingProgress = (confirmedOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'delivered') {
      this.loadingProgress = (deliveredOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'received') {
      this.loadingProgress = (receivedOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'prepaid') {
      this.loadingProgress = (prepaidOrders / ordersPerLevel) * 100;
    }
  }

  goToProduct(): void {
    this._router.navigate([this._appURLs.PRODUCTS_V2_URL, this.dynamicIncentiveData.productId]);
  }

  public get orderTypeText(): string {
    return this.trans(
      `DYNAMIC_INCENTIVE.TYPES.${this.dynamicIncentiveData.orderType.toUpperCase()}`,
    );
  }
}
