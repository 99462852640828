<div class="dynamic-incentive">
  <div class="dynamic-incentive__program">
    <div
      class="dynamic-incentive__program__right-part"
      [ngClass]="programStatus === 'DECLINED' || programStatus === 'EXPIRED' ? 'dimmed' : ''"
      *ngIf="!shouldOnlyDisplayIncentiveWidget"
    >
      <img
        src="{{
          incentiveProgramData.bucketType === 'PRE_PAID'
            ? assetsPath + 'prepaid-img.svg'
            : incentiveProgramData.bucketType === 'PRODUCT_BASED'
            ? assetsPath + 'sku.svg'
            : assetsPath + 'orders.svg'
        }}"
      />
      <div
        class="dynamic-incentive__program__right-part__rewards"
        *ngIf="
          programStatus === 'OFFERED' || programStatus === 'DECLINED' || programStatus === 'EXPIRED'
        "
      >
        <div>
          <img src="{{ assetsPath + 'plus.svg' }}" />
          <img
            class="dynamic-incentive__program__right-part__rewards__img"
            src="{{ assetsPath + 'money.svg' }}"
          />
        </div>
        <p class="body1--bold secondary-yellow-color">
          {{ dynamicIncentiveData.maxRewards }}
          {{ currency | currencyTranslate | translate | currencyShortName }}
        </p>
      </div>
    </div>
    <div class="dynamic-incentive__program__middle-part">
      <p *ngIf="programStatus === 'IN_PROGRESS'" class="caption2--regular white-color">
        {{ 'DYNAMIC_INCENTIVE.CHALLENGE_ACCEPTED_IN' | translate }} {{ acceptedDate }}
      </p>
      <div
        class="dynamic-incentive__program__middle-part__clock"
        [ngClass]="programStatus === 'DECLINED' || programStatus === 'OFFERED' ? 'dimmed' : ''"
      >
        <img
          *ngIf="programStatus !== 'OFFERED' && programStatus !== 'EXPIRED'"
          class="dynamic-incentive__program__middle-part__clock__img"
          src="{{ assetsPath + 'clock.svg' }}"
        />
        <div
          *ngIf="programStatus !== 'OFFERED' && programStatus !== 'EXPIRED'"
          class="dynamic-incentive__program__middle-part__clock__time"
          [ngClass]="
            programStatus === 'ACHIEVED' ||
            programStatus === 'IN_PROGRESS_EXTRA_TIME' ||
            programStatus === 'NOT_ACHIEVED'
              ? 'dynamic-incentive__program__middle-part__clock__time__achieved'
              : ''
          "
        >
          <p class="caption1--bold white-color" *ngIf="programStatus === 'IN_PROGRESS'">
            {{ endDateCountDownTimer | countdownTimerPipe : 'named-day:hr:min:secs' }}
          </p>
          <p class="caption1--bold white-color" *ngIf="programStatus === 'DECLINED'">
            {{ 'DYNAMIC_INCENTIVE.REJECTED' | translate }}
          </p>
          <p
            class="caption1--bold maroon-color"
            *ngIf="
              programStatus === 'ACHIEVED' ||
              programStatus === 'IN_PROGRESS_EXTRA_TIME' ||
              programStatus === 'NOT_ACHIEVED'
            "
          >
            {{ 'DYNAMIC_INCENTIVE.ACHIEVED' | translate }}
          </p>
        </div>
        <p
          *ngIf="programStatus === 'IN_PROGRESS_EXTRA_TIME' || programStatus === 'NOT_ACHIEVED'"
          class="space-right body1--bold white-color"
        >
          +
        </p>
        <div *ngIf="programStatus === 'IN_PROGRESS_EXTRA_TIME' || programStatus === 'NOT_ACHIEVED'">
          <div class="extra-time-container">
            <img class="extra-time-container__img" src="{{ assetsPath + 'sand-clock.svg' }}" />
            <p
              *ngIf="programStatus === 'IN_PROGRESS_EXTRA_TIME'"
              class="caption1--bold white-color"
            >
              {{ extraTimeCountDownTimer | countdownTimerPipe : 'named-day:hr:min:secs' }}
            </p>
            <p class="caption1--bold red-pink-color" *ngIf="programStatus === 'NOT_ACHIEVED'">
              {{ 'DYNAMIC_INCENTIVE.EXTRA_TIME_ACHIEVED' | translate }}
            </p>
          </div>
        </div>
        <p *ngIf="programStatus === 'ACHIEVED'" class="space-right caption2--regular white-color">
          {{ 'DYNAMIC_INCENTIVE.CHALLENGE_ACHIEVED' | translate }}
        </p>
      </div>
      <div
        class="dynamic-incentive__program__middle-part__names"
        [ngClass]="
          programStatus === 'DECLINED' ||
          programStatus === 'NOT_ACHIEVED' ||
          programStatus === 'EXPIRED'
            ? 'dimmed'
            : ''
        "
      >
        <p
          *ngIf="programStatus === 'IN_PROGRESS_EXTRA_TIME'"
          class="extra-time-text caption2--regular white-color"
        >
          {{ 'DYNAMIC_INCENTIVE.EXTRA_TIME_TEXT' | translate : { extraTimeInDays } }}
        </p>
        <p
          class="caption1--bold secondary-yellow-color"
          *ngIf="
            programStatus === 'DECLINED' ||
            programStatus === 'OFFERED' ||
            programStatus === 'EXPIRED'
          "
        >
          {{ incentiveProgramName }}
        </p>
        <p class="heading2--extra-bold white-color" *ngIf="programStatus !== 'ACHIEVED'">
          {{ 'DYNAMIC_INCENTIVE.REACH' | translate }}{{ dynamicIncentiveData?.maxNumberOfOrders }}
          {{ 'DYNAMIC_INCENTIVE.NEW_ORDERS' | translate }}
          <span class="heading2--extra-bold secondary-yellow-color">{{
            'DYNAMIC_INCENTIVE.TYPES.' + dynamicIncentiveData.orderType.toUpperCase() | translate
          }}</span>
          <span
            *ngIf="dynamicIncentiveData.sku"
            (click)="goToProduct()"
            class="heading2--extra-bold white-color clickable"
            >{{ 'DYNAMIC_INCENTIVE.FROM_PRODUCT' | translate }}{{ dynamicIncentiveData.sku }}</span
          >
        </p>
        <p
          *ngIf="programStatus === 'OFFERED' || programStatus === 'EXPIRED'"
          class="heading2--extra-bold white-color"
        >
          {{ 'DYNAMIC_INCENTIVE.DURING' | translate }} {{ durationInDays }}
          {{ 'DATE_FORMAT.DAY' | translate }}
        </p>
        <p class="heading2--extra-bold white-color" *ngIf="programStatus === 'ACHIEVED'">
          {{ 'DYNAMIC_INCENTIVE.REACHED' | translate }}{{ dynamicIncentiveData?.maxNumberOfOrders }}
          {{ 'DYNAMIC_INCENTIVE.ORDER' | translate }}
          <span class="heading2--extra-bold white-color"
            >{{
              'DYNAMIC_INCENTIVE.TYPES.' + dynamicIncentiveData.orderType.toUpperCase() | translate
            }}
          </span>
          <span>🎉</span>
        </p>
      </div>
      <div class="dynamic-incentive__program__middle-part__accept-ignore">
        <div
          class="dynamic-incentive__program__middle-part__accept-ignore__duration"
          *ngIf="programStatus === 'OFFERED' || programStatus === 'EXPIRED'"
        >
          <img src="{{ assetsPath + 'sand-clock.svg' }}" />
          <p *ngIf="programStatus === 'OFFERED'" class="caption1--regular white-color">
            {{ 'DYNAMIC_INCENTIVE.ENDS_IN' | translate }}
            {{ expiryDateCountDownTimer | countdownTimerPipe : 'named-day:hr:min:secs' }}
          </p>
          <p *ngIf="programStatus === 'EXPIRED'" class="caption1--regular white-color">
            {{ 'DYNAMIC_INCENTIVE.EXPIRED' | translate }}
          </p>
          <p class="caption1--regular white-color"></p>
        </div>
        <div
          class="dynamic-incentive__program__middle-part__accept-ignore__cta"
          [ngClass]="
            programStatus === 'IN_PROGRESS' || programStatus === 'IN_PROGRESS_EXTRA_TIME'
              ? 'dynamic-incentive__program__middle-part__accept-ignore__cta__challenge-accepted'
              : ''
          "
        >
          <button
            [disabled]="programStatus === 'EXPIRED' || isButtonsDisabled"
            *ngIf="programStatus === 'OFFERED' || programStatus === 'EXPIRED'"
            class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__cta__accept body1--bold"
            (click)="onAcceptClicked()"
          >
            {{ 'DYNAMIC_INCENTIVE.ACCEPT_CHALLENGE' | translate }}
          </button>
          <button
            [disabled]="programStatus === 'EXPIRED' || isButtonsDisabled"
            *ngIf="programStatus === 'OFFERED' || programStatus === 'EXPIRED'"
            class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__cta__ignore body1--bold"
            (click)="onDeclineClicked()"
          >
            {{ 'DYNAMIC_INCENTIVE.DECLINE_CHALLENGE' | translate }}
          </button>
          <div
            *ngIf="programStatus === 'DECLINED'"
            class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__feedback"
          >
            <p class="caption2--regular white-color">
              {{ 'DYNAMIC_INCENTIVE.REJECTION_REASON' | translate }}
            </p>
            <button
              (click)="onFeedbackClicked()"
              class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__cta__feedback body1--bold"
            >
              {{ 'DYNAMIC_INCENTIVE.FEEDBACK' | translate }}
            </button>
          </div>
          <div
            *ngIf="programStatus === 'IN_PROGRESS' || programStatus === 'IN_PROGRESS_EXTRA_TIME'"
          >
            <p class="caption1--regular white-color">
              {{ 'DYNAMIC_INCENTIVE.CHALLENGE_PROGRESS' | translate }}
            </p>
            <div class="accepted-data">
              <div class="progress-bar-container">
                <mat-progress-bar
                  class="incentive-progress-bar"
                  mode="determinate"
                  value="{{ loadingProgress }}"
                >
                </mat-progress-bar>
                <div class="incentive-progress-bar-content">
                  <p class="body2--medium white-color">
                    <span *ngIf="dynamicIncentiveData.orderType === 'confirmed'"
                      >{{ dynamicLevels.numOfOrders }}/{{
                        orderProgressData?.confirmedOrders
                      }}</span
                    >
                    <span *ngIf="dynamicIncentiveData.orderType === 'delivered'"
                      >{{ dynamicLevels.numOfOrders }}/{{
                        orderProgressData?.deliveredOrders
                      }}</span
                    >
                    <span *ngIf="dynamicIncentiveData.orderType === 'received'"
                      >{{ dynamicLevels.numOfOrders }}/{{
                        orderProgressData?.totalPlacedOrders
                      }}</span
                    >
                    <span *ngIf="dynamicIncentiveData.orderType === 'prepaid'"
                      >{{ dynamicLevels.numOfOrders }}/{{
                        orderProgressData?.prePaidOrdersCount
                      }}</span
                    >
                    {{ 'DYNAMIC_INCENTIVE.ORDER' | translate }}
                    {{
                      'DYNAMIC_INCENTIVE.TYPES.' + dynamicIncentiveData.orderType.toUpperCase()
                        | translate
                    }}
                  </p>
                  <img
                    class="incentive-progress-bar-content__img"
                    src="{{ assetsPath + 'money.svg' }}"
                  />
                </div>
              </div>
              <img class="line-separator" src="{{ assetsPath + 'line.svg' }}" />
              <div>
                <p class="body1--bold secondary-yellow-color">
                  {{ dynamicIncentiveData.maxRewards }}
                </p>
                <p class="body1--bold secondary-yellow-color">
                  {{ currency | currencyTranslate | translate | currencyShortName }}
                </p>
              </div>
            </div>
            <div
              class="order-details"
              *ngIf="
                dynamicIncentiveData.bucketType !== 'PRE_PAID' &&
                (programStatus === 'IN_PROGRESS' || programStatus === 'IN_PROGRESS_EXTRA_TIME')
              "
            >
              <p class="caption1--bold white-color-dimmed">
                {{ 'DYNAMIC_INCENTIVE.ORDERS_ADDED_DETAILS' | translate }}
              </p>
              <div class="order-details__data">
                <p class="caption1--bold white-color">
                  {{ 'DYNAMIC_INCENTIVE.ORDERS_DURING_CHALLENGE' | translate }}
                </p>
                <p class="caption1--bold white-color">{{ orderProgressData?.totalPlacedOrders }}</p>
              </div>
              <div class="order-details__data">
                <p class="caption1--bold secondary-yellow-color">
                  {{ 'DYNAMIC_INCENTIVE.ORDERS_PENDING' | translate }}
                </p>
                <p class="caption1--bold secondary-yellow-color">
                  {{ orderProgressData?.pendingConfirmation }}
                </p>
              </div>
              <div class="order-details__data">
                <p class="caption1--bold white-color">
                  {{ 'DYNAMIC_INCENTIVE.ORDERS_CHALLENGE_CONFIRMED' | translate }}
                </p>
                <p class="caption1--bold white-color">{{ orderProgressData?.confirmedOrders }}</p>
              </div>
            </div>
            <div
              class="order-details"
              *ngIf="
                dynamicIncentiveData.bucketType === 'PRE_PAID' &&
                (programStatus === 'IN_PROGRESS' || programStatus === 'IN_PROGRESS_EXTRA_TIME')
              "
            >
              <div class="order-details__data">
                <p class="caption1--bold white-color">
                  {{ 'DYNAMIC_INCENTIVE.PRE_ORDER_ORDERS_DURING_CHALLENGE' | translate }}
                </p>
                <p class="caption1--bold white-color">
                  {{ orderProgressData?.prePaidOrdersCount }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="achieved-lost-container"
            *ngIf="programStatus === 'ACHIEVED' || programStatus === 'NOT_ACHIEVED'"
          >
            <div
              class="achieved-container"
              [ngClass]="programStatus === 'NOT_ACHIEVED' ? 'achieved-container__lost' : ''"
            >
              <div>
                <p class="body1--bold white-color" *ngIf="programStatus !== 'NOT_ACHIEVED'">
                  {{ 'DYNAMIC_INCENTIVE.YOU_WON' | translate }}
                </p>
                <p class="caption2--medium white-color" *ngIf="programStatus !== 'NOT_ACHIEVED'">
                  {{ 'DYNAMIC_INCENTIVE.YOU_WON_NOTE' | translate }}
                </p>
              </div>
              <p class="caption2--regular white-color" *ngIf="programStatus === 'NOT_ACHIEVED'">
                {{ 'DYNAMIC_INCENTIVE.NOT_ACHIEVED_TEXT' | translate }}
              </p>
              <img
                src="{{ assetsPath + 'money.svg' }}"
                [ngClass]="programStatus === 'NOT_ACHIEVED' ? 'dimmed' : ''"
              />
              <p
                [ngClass]="programStatus === 'NOT_ACHIEVED' ? 'dimmed' : ''"
                class="heading2--extra-bold secondary-yellow-color"
              >
                {{ dynamicIncentiveData.maxRewards }}
                {{ currency | currencyTranslate | translate | currencyShortName }}
              </p>
            </div>
            <div class="order-details__data">
              <p class="caption1--bold white-color">
                {{ 'DYNAMIC_INCENTIVE.CONFIRMED_ORDERS_DURING_CHALLENGE' | translate }}
              </p>
              <p class="caption1--bold white-color">
                {{ dynamicIncentiveData?.challengeResult?.numOfAchievedOrders }}
              </p>
            </div>
          </div>
        </div>
        <button
          (click)="onFeedbackClicked()"
          *ngIf="programStatus === 'ACHIEVED' || programStatus === 'NOT_ACHIEVED'"
          class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__cta__feedback body1--bold"
        >
          {{ 'DYNAMIC_INCENTIVE.FEEDBACK' | translate }}
        </button>
      </div>
    </div>
    <div class="dynamic-incentive__program__left-part" *ngIf="!shouldOnlyDisplayIncentiveWidget">
      <div
        class="dynamic-incentive__program__middle-part__clock"
        *ngIf="
          programStatus !== 'DECLINED' &&
          programStatus !== 'ACHIEVED' &&
          programStatus !== 'NOT_ACHIEVED' &&
          programStatus !== 'EXPIRED'
        "
      >
        <img
          class="dynamic-incentive__program__middle-part__clock__img"
          src="{{ assetsPath + 'guard.svg' }}"
        />
        <div class="dynamic-incentive__program__middle-part__clock__time">
          <p class="caption1--bold white-color">
            {{ 'DYNAMIC_INCENTIVE.TERMS_CONDITIONS' | translate }}
          </p>
        </div>
      </div>
      <div
        *ngIf="
          programStatus !== 'DECLINED' &&
          programStatus !== 'ACHIEVED' &&
          programStatus !== 'NOT_ACHIEVED' &&
          programStatus !== 'EXPIRED'
        "
      >
        <div
          class="dynamic-incentive__program__left-part__term body2--regular white-color"
          *ngFor="let term of termsAndConditions; let i = index"
        >
          <p class="dynamic-incentive__program__left-part__number caption3--bold">{{ i + 1 }}</p>
          <p class="body2--regular white-color">{{ term }}</p>
        </div>
      </div>
      <div
        *ngIf="
          programStatus === 'DECLINED' ||
          programStatus === 'ACHIEVED' ||
          programStatus === 'NOT_ACHIEVED' ||
          programStatus === 'EXPIRED'
        "
      >
        <p class="declined-text heading2--bold white-color">
          {{ 'DYNAMIC_INCENTIVE.WAIT_NEXT_CHALLENGE' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
