<div class="dynamic-incentive">
  <div class="dynamic-incentive__program">
    <div class="dynamic-incentive__program-left-wrapper">
      <div class="dynamic-incentive__program__right-part" *ngIf="!shouldOnlyDisplayIncentiveWidget">
        <img
          src="{{
            incentiveProgramData.bucketType === 'PRE_PAID'
              ? assetsPath + 'prepaid-img.svg'
              : incentiveProgramData.bucketType === 'PRODUCT_BASED'
              ? assetsPath + 'sku.svg'
              : assetsPath + 'orders.svg'
          }}"
        />
        <div
          class="dynamic-incentive__program__right-part__rewards"
          *ngIf="
            programStatus === 'OFFERED' ||
            programStatus === 'DECLINED' ||
            programStatus === 'EXPIRED'
          "
        >
          <div>
            <img src="{{ assetsPath + 'plus.svg' }}" />
            <img
              class="dynamic-incentive__program__right-part__rewards__img"
              src="{{ assetsPath + 'money.svg' }}"
            />
          </div>
          <p class="body1--bold secondary-yellow-color">
            {{ dynamicIncentiveData.maxRewards }}
            {{ currency | currencyTranslate | translate | currencyShortName }}
          </p>
        </div>
      </div>

      <div class="dynamic-incentive__program__middle-part">
        <!-- Challenge time -->
        <p *ngIf="programStatus === 'IN_PROGRESS'" class="caption2--regular white-color">
          {{ 'DYNAMIC_INCENTIVE.CHALLENGE_ACCEPTED_IN' | translate }} {{ getAcceptedDate() }}
        </p>

        <div class="dynamic-incentive__program__middle-part__clock">
          <img
            *ngIf="programStatus !== 'OFFERED' && programStatus !== 'EXPIRED'"
            class="dynamic-incentive__program__middle-part__clock__img"
            src="{{ assetsPath + 'clock.svg' }}"
          />
          <div
            class="dynamic-incentive__program__middle-part__clock__time"
            [ngClass]="
              challengeTimeIsUp
                ? 'dynamic-incentive__program__middle-part__clock__time__achieved'
                : ''
            "
          >
            <p class="caption1--bold white-color" *ngIf="!challengeTimeIsUp">
              {{ endDateCountDownTimer | countdownTimerPipe : 'named-day:hr:min:secs' }}
            </p>
            <p class="caption1--bold white-color" *ngIf="programStatus === 'DECLINED'">
              {{ 'DYNAMIC_INCENTIVE.REJECTED' | translate }}
            </p>
            <p class="caption1--bold maroon-color" *ngIf="challengeTimeIsUp">
              {{ 'DYNAMIC_INCENTIVE.ENDED' | translate }}
            </p>
          </div>
          <p *ngIf="hasGracePeriod" class="space-right body1--bold white-color">+</p>
          <!-- Extra time, Grace period -->
          <div *ngIf="hasGracePeriod">
            <div class="extra-time-container">
              <img class="extra-time-container__img" src="{{ assetsPath + 'sand-clock.svg' }}" />
              <p class="caption1--bold white-color">
                {{ extraTimeCountDownTimer | countdownTimerPipe : 'named-day:hr:min:secs' }}
              </p>
              <p class="caption1--bold red-pink-color" *ngIf="programStatus === 'NOT_ACHIEVED'">
                {{ 'DYNAMIC_INCENTIVE.EXTRA_TIME_ACHIEVED' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="dynamic-incentive__program__middle-part__names" *ngIf="!isAchieved">
          <p
            *ngIf="programStatus === 'IN_PROGRESS_EXTRA_TIME'"
            class="extra-time-text caption2--regular white-color mb-3 extra-time-text"
          >
            {{ 'DYNAMIC_INCENTIVE.EXTRA_TIME_TEXT' | translate : { extraTimeInDays } }}
          </p>
          <p
            class="caption1--bold secondary-yellow-color"
            *ngIf="
              programStatus === 'DECLINED' ||
              programStatus === 'OFFERED' ||
              programStatus === 'EXPIRED'
            "
          >
            {{ incentiveProgramName }}
          </p>
          <p class="heading2--extra-bold white-color mb-3" *ngIf="programStatus !== 'ACHIEVED'">
            {{ 'DYNAMIC_INCENTIVE.REACH' | translate }}
            {{ dynamicIncentiveData?.maxNumberOfOrders }}
            {{ 'DYNAMIC_INCENTIVE.NEW_ORDERS' | translate }}
            <span class="heading2--extra-bold secondary-yellow-color">{{ orderTypeText }}</span>
            <span
              *ngIf="dynamicIncentiveData.sku"
              (click)="goToProduct()"
              class="heading2--extra-bold white-color clickable"
              >{{ 'DYNAMIC_INCENTIVE.FROM_PRODUCT' | translate
              }}{{ dynamicIncentiveData.sku }}</span
            >
          </p>
          <p
            *ngIf="programStatus === 'OFFERED' || programStatus === 'EXPIRED'"
            class="heading2--extra-bold white-color"
          >
            {{ 'DYNAMIC_INCENTIVE.DURING' | translate }} {{ durationInDays }}
            {{ 'DATE_FORMAT.DAY' | translate }}
          </p>
        </div>
        <!-- End of challenge time -->
        <div class="dynamic-incentive__program__middle-part__accept-ignore">
          <!-- Progress Bar -->
          <div
            *ngIf="shouldDisplayProgressBar"
            class="dynamic-incentive__program__middle-part__accept-ignore__cta"
            [ngClass]="
              'dynamic-incentive__program__middle-part__accept-ignore__cta__challenge-accepted'
            "
          >
            <div style="width: 100%">
              <p class="caption1--regular white-color">
                {{ 'DYNAMIC_INCENTIVE.CHALLENGE_PROGRESS' | translate }}
              </p>
              <div class="accepted-data">
                <div class="progress-bar-container">
                  <mat-progress-bar
                    class="incentive-progress-bar"
                    mode="determinate"
                    value="{{ loadingProgress }}"
                  >
                  </mat-progress-bar>

                  <div class="incentive-progress-bar-content">
                    <p class="body2--medium white-color">
                      <span class="achieved-orders">{{ achievedOrdersProgress() }}</span>
                      {{ 'DYNAMIC_INCENTIVE.ORDER' | translate }}
                      {{
                        'DYNAMIC_INCENTIVE.TYPES.' + dynamicIncentiveData.orderType.toUpperCase()
                          | translate
                      }}
                    </p>
                    <img
                      class="incentive-progress-bar-content__img"
                      src="{{ assetsPath + 'money.svg' }}"
                    />
                  </div>
                </div>
                <img class="line-separator" src="{{ assetsPath + 'line.svg' }}" />
                <div>
                  <p class="body1--bold secondary-yellow-color">
                    {{ dynamicIncentiveData.maxRewards }}
                  </p>
                  <p class="body1--bold secondary-yellow-color">
                    {{ currency | currencyTranslate | translate | currencyShortName }}
                  </p>
                </div>
              </div>
              <div class="order-details">
                <p class="caption1--semibold white-color-dimmed">
                  {{ 'DYNAMIC_INCENTIVE.ORDERS_ADDED_DETAILS' | translate }}
                </p>
                <div class="order-details__data">
                  <p class="caption1--bold white-color">
                    {{ 'DYNAMIC_INCENTIVE.ORDERS_DURING_CHALLENGE' | translate }}
                  </p>
                  <p class="caption1--bold white-color">
                    {{ dynamicIncentiveData?.maxNumberOfOrders }}
                  </p>
                </div>
                <div *ngIf="orderProgressData?.pendingConfirmation" class="order-details__data">
                  <p class="caption1--bold secondary-yellow-color">
                    {{ 'DYNAMIC_INCENTIVE.ORDERS_PENDING' | translate }}
                  </p>
                  <p class="caption1--bold secondary-yellow-color">
                    {{ orderProgressData?.pendingConfirmation }}
                  </p>
                </div>
                <div *ngIf="orderProgressData?.confirmedOrders" class="order-details__data">
                  <p class="caption1--bold white-color">
                    {{ 'DYNAMIC_INCENTIVE.ORDERS_CHALLENGE_CONFIRMED' | translate }}
                  </p>
                  <p class="caption1--bold white-color">{{ orderProgressData?.confirmedOrders }}</p>
                </div>
              </div>
              <div
                class="order-details"
                *ngIf="
                  dynamicIncentiveData.bucketType === 'PRE_PAID' &&
                  (programStatus === 'IN_PROGRESS' || programStatus === 'IN_PROGRESS_EXTRA_TIME')
                "
              >
                <div class="order-details__data">
                  <p class="caption1--bold white-color">
                    {{ 'DYNAMIC_INCENTIVE.PRE_ORDER_ORDERS_DURING_CHALLENGE' | translate }}
                  </p>
                  <p class="caption1--bold white-color">
                    {{ orderProgressData?.prePaidOrdersCount }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Progress Bar -->

            <div *ngIf="hasCarnival" class="profits__carnival">
              <div class="carnival__tent__img">
                <img src="assets/img/incentive-program/carnival-tent.svg" alt="Carnival Tent" />
              </div>

              <div *ngIf="didNotWinInCarnival" class="profits__carnival__text">
                <p>
                  {{ 'DYNAMIC_INCENTIVE.CHALLENGE_OVER_PROFITS_CARNIVAL' | translate }}
                </p>
              </div>
              <div *ngIf="programStatus === 'IN_PROGRESS'" class="profits__carnival__text">
                <h4>{{ trans('DYNAMIC_INCENTIVE.PROFITS_CARNIVAL') }}</h4>
                <p>
                  {{ trans('DYNAMIC_INCENTIVE.PROFITS_CARNIVAL_DESC') }}
                </p>
              </div>
              <div *ngIf="programStatus === 'IN_PROGRESS'" class="carnival__security__img">
                <img src="assets/img/incentive-program/security.svg" alt="Security" />
              </div>
            </div>
          </div>

          <!-- Carnival -->

          <!-- You Win! -->
          <div *ngIf="programStatus === 'ACHIEVED'" class="challenge__win__container">
            <h2 class="challenge__win__title">
              {{ 'DYNAMIC_INCENTIVE.REACHED' | translate }}
              {{ dynamicIncentiveData?.maxNumberOfOrders }}
              {{ 'DYNAMIC_INCENTIVE.ORDER' | translate }}
              <span class="heading2--extra-bold white-color"
                >{{
                  'DYNAMIC_INCENTIVE.TYPES.' + dynamicIncentiveData.orderType.toUpperCase()
                    | translate
                }}
              </span>
              <span>🎉</span>
            </h2>

            <div class="challenge__win">
              <div class="challenge__win__header">
                <h3 class="challenge__win__header__title">
                  {{ 'DYNAMIC_INCENTIVE.YOU_WIN' | translate }}
                </h3>

                <img
                  src="{{ assetsPath + 'money.svg' }}"
                  alt="Money"
                  class="challenge__win__header__img"
                />

                <div>
                  <p class="body1--bold secondary-yellow-color">
                    {{
                      dynamicIncentiveData.challengeResult?.baseRewards ??
                        dynamicIncentiveData.challengeResult?.rewards
                    }}
                  </p>
                  <p class="body1--bold secondary-yellow-color">
                    {{ currency | currencyTranslate | translate | currencyShortName }}
                  </p>
                </div>
              </div>

              <div class="challenge__win__body" *ngIf="shouldDisplayCarnivalDetails">
                <div class="challenge__win__body_upper">
                  <div class="challenge__win__body_upper__img">
                    <img src="assets/img/incentive-program/carnival-tent.svg" alt="Carnival Tent" />
                  </div>

                  <div class="challenge__win__body_upper__text">
                    <h4 class="challenge__win__body_upper__text__title">
                      {{ 'DYNAMIC_INCENTIVE.YOU_WIN_CARNIVAL_TITLE' | translate }}
                    </h4>
                    <p class="challenge__win__body_upper__text__description">
                      {{
                        'DYNAMIC_INCENTIVE.YOU_WIN_CARNIVAL_DESCRIPTION'
                          | translate
                            : {
                                amountWithCurrency: rewardPerExtraOrder(),
                                orderType: orderTypeText
                              }
                      }}
                    </p>
                  </div>
                </div>

                <div class="divider"></div>

                <div class="challenge__win__body_lower">
                  <p class="challenge__win__body_lower__subtitle">
                    {{ 'DYNAMIC_INCENTIVE.YOU_WIN_CARNIVAL_DETAILS_TITLE' | translate }}
                  </p>

                  <div
                    *ngIf="orderProgressData?.pendingConfirmation"
                    class="challenge__win__body_lower__requests"
                  >
                    <p>
                      {{ 'DYNAMIC_INCENTIVE.ORDERS_PENDING' | translate }}
                    </p>
                    <p>
                      {{ orderProgressData?.pendingConfirmation }}
                    </p>
                  </div>
                  <div class="challenge__win__body_lower__requests">
                    <p>
                      {{ 'DYNAMIC_INCENTIVE.YOU_WIN_CARNIVAL_DETAILS_REQUESTS' | translate }}
                    </p>
                    <p>{{ numberOfAdditionalOrders() }}</p>
                  </div>
                  <div class="challenge__win__body_lower__item__profits">
                    <p>
                      {{ 'DYNAMIC_INCENTIVE.YOU_WIN_CARNIVAL_DETAILS_PROFIT' | translate }}
                    </p>
                    <p>
                      {{ totalRewardsForExtraOrders() }}
                      {{ currency | currencyTranslate | translate | currencyShortName }}
                    </p>
                  </div>
                </div>
              </div>

              <p *ngIf="hasCarnival && challengeInProgress" class="challenge__win__footer">
                {{ 'DYNAMIC_INCENTIVE.CARNIVAL_IN_PROGRESS_FOOTER' | translate }}
              </p>

              <div *ngIf="hasCarnival && didNotWinInCarnival" class="profits__carnival mt-0">
                <div class="carnival__tent__img">
                  <img src="assets/img/incentive-program/carnival-tent.svg" alt="Carnival Tent" />
                </div>

                <div class="profits__carnival__text">
                  <p>
                    {{ 'DYNAMIC_INCENTIVE.CHALLENGE_OVER_PROFITS_CARNIVAL' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            (click)="onFeedbackClicked()"
            *ngIf="shouldDisplayFeedback"
            class="dynamic-incentive-btn dynamic-incentive__program__middle-part__accept-ignore__cta__feedback body1--bold"
          >
            {{ 'DYNAMIC_INCENTIVE.FEEDBACK' | translate }}
          </button>
          <!-- End of Carnival -->
        </div>
      </div>

      <div class="dynamic-incentive__program__left-part" *ngIf="!shouldOnlyDisplayIncentiveWidget">
        <div class="dynamic-incentive__program__middle-part__clock">
          <img
            class="dynamic-incentive__program__middle-part__clock__img"
            src="{{ assetsPath + 'guard.svg' }}"
          />
          <div class="dynamic-incentive__program__middle-part__clock__time">
            <p class="caption1--bold white-color terms-conditions-txt">
              {{ 'DYNAMIC_INCENTIVE.TERMS_CONDITIONS' | translate }}
            </p>
          </div>
        </div>
        <div>
          <div
            class="dynamic-incentive__program__left-part__term body2--regular white-color"
            *ngFor="let term of termsAndConditions; let i = index"
          >
            <p class="dynamic-incentive__program__left-part__number caption3--bold">{{ i + 1 }}</p>
            <p class="body2--regular white-color">{{ localizedValue(term) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
