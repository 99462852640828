import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { parseError } from '@presentation/shared/error';
import { toastError } from '@presentation/shared/toast';
import { DynamicIncentiveProgramModel } from '../../../../core/domain/dynamic-incentive-program.model';
import { GetDynamicIncentiveProgramUseCase } from '../../../../core/usecases/dynamic-incentive-program/get-dynamic-incentive-program.usecase';
import { LocalizedComponent } from '../../../base/localized.component';
import { IncentiveProgramChallengeCardActionComponent } from './incentive-program-challenge-card-action/incentive-program-challenge-card-action.component';
import { IncentiveProgramChallengeComponent } from './incentive-program-challenge/incentive-program-challenge.component';

@Component({
  selector: 'app-incentive-program-challenges',
  standalone: true,
  imports: [
    CommonModule,
    IncentiveProgramChallengeComponent,
    NgClass,
    NgFor,
    NgIf,
    IncentiveProgramChallengeCardActionComponent,
  ],
  templateUrl: './incentive-program-challenges.component.html',
  styleUrls: ['./incentive-program-challenges.component.scss'],
})
export class IncentiveProgramChallengesComponent extends LocalizedComponent implements OnInit {
  @Input() shouldOnlyDisplayIncentiveWidget = false;

  @Input() challenges: DynamicIncentiveProgramModel[];

  @Output() actionClicked: EventEmitter<void> = new EventEmitter();

  public activeChallenge?: DynamicIncentiveProgramModel;

  // Pending challenges are the challenges that merchant didn't accept or reject yet
  public pendingChallenges: DynamicIncentiveProgramModel[] = [];

  // Active challenges that the merchant have accepted
  public activeChallenges: DynamicIncentiveProgramModel[] = [];

  public assetsPath = 'assets/img/dynamic-incentive/';

  public constructor(
    private _getDynamicIncentiveProgramUseCase: GetDynamicIncentiveProgramUseCase,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.prepareChallenges();
  }

  public prepareChallenges(): void {
    this.pendingChallenges = [];
    this.activeChallenges = [];
    this.activeChallenge = undefined;

    for (const challenge of this.challenges) {
      if (challenge.status === 'OFFERED') {
        this.pendingChallenges.push(challenge);
      } else {
        this.activeChallenges.push(challenge);
        if (!this.activeChallenge) {
          this.activeChallenge = challenge;
        }
      }
    }
  }

  public setActiveChallenge(challenge?: DynamicIncentiveProgramModel): void {
    this.activeChallenge = challenge;
  }

  public reloadChallenges(): void {
    this._getDynamicIncentiveProgramUseCase.execute().subscribe({
      next: (dynamicIncentiveData) => {
        this.challenges = dynamicIncentiveData;
        this.prepareChallenges();
      },
      error: (error) => {
        if (error.status === 404) {
          this.challenges = [];
          this.prepareChallenges();
        } else {
          toastError(parseError(error));
        }
      },
    });
  }
}
