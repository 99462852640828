<div class="dynamic-incentive">
  <div class="bubbles-wrapper">
    <div class="bubbles-container">
      <div class="bubbles-item">
        <div *ngIf="pendingChallenges.length > 0" class="pending-challenges-count">
          {{ pendingChallenges.length }}
        </div>
        <img
          src="{{ assetsPath + 'plus-circle.svg' }}"
          role="button"
          (click)="setActiveChallenge()"
          [ngClass]="!activeChallenge ? 'active' : ''"
          alt="no-challenge"
        />
      </div>
      <div *ngFor="let challenge of activeChallenges" class="bubbles-item">
        <img
          role="button"
          (click)="setActiveChallenge(challenge)"
          src="{{
            challenge.bucketType === 'PRE_PAID'
              ? assetsPath + 'card.svg'
              : challenge.bucketType === 'PRODUCT_BASED'
              ? assetsPath + 'sku.svg'
              : assetsPath + 'truck.svg'
          }}"
          [ngClass]="
            challenge.merchantChallengeBusinessId === activeChallenge?.merchantChallengeBusinessId
              ? 'active colored-border'
              : ''
          "
          alt="challenge.bucketType"
        />
      </div>
    </div>

    <div *ngIf="activeChallenge?.startDate" class="challenge__date">
      <div class="challenge__start">
        <p class="challenge__start__title">
          {{ trans('DYNAMIC_INCENTIVE.CHALLENGE_START_DATE_TITLE') }}
        </p>
        <p class="challenge__start__date">{{ activeChallenge?.startDate | date : 'yyyy-MM-dd' }}</p>
      </div>
      <div class="challenge__separator"></div>
      <div class="challenge__end">
        <p class="challenge__end__title">
          {{ trans('DYNAMIC_INCENTIVE.CHALLENGE_END_DATE_TITLE') }}
        </p>
        <p class="challenge__end__date">{{ activeChallenge?.endDate | date : 'yyyy-MM-dd' }}</p>
      </div>
    </div>
  </div>
  <div class="dynamic-incentive__program">
    <ng-container *ngIf="!activeChallenge">
      <app-incentive-program-challenge-card-action
        *ngFor="let challenge of pendingChallenges"
        [incentiveProgramData]="challenge"
        style="width: 100%"
        (actionClicked)="reloadChallenges()"
        [shouldOnlyDisplayIncentiveWidget]="shouldOnlyDisplayIncentiveWidget"
      ></app-incentive-program-challenge-card-action>

      <div *ngIf="pendingChallenges.length === 0" class="no-challenge-wrapper">
        <div class="no-challenge">
          <div>{{ trans('INCENTIVE_PROGRAM.STAY_TUNED') }}🚀</div>
        </div>
      </div>
    </ng-container>
    <app-incentive-program-challenge
      *ngIf="activeChallenge"
      [incentiveProgramData]="activeChallenge"
      style="width: 100%"
      [shouldOnlyDisplayIncentiveWidget]="shouldOnlyDisplayIncentiveWidget"
    ></app-incentive-program-challenge>
  </div>
</div>
